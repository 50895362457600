
.popup-layer {
    position: absolute;
    z-index: 10000;
    animation: popup-fade 0.35s ease-out 0.35s forwards;
    opacity: 0;
    &__body {
        height: auto !important;
    }
    &__foot {
        padding: 0 12px;
        color: #fff !important;
        background-color: #666;
        ::v-deep {
            .v-input--checkbox .theme--light.v-label,
            .v-input--checkbox .theme--light.v-icon,
            .v-btn {
                color: #fff !important;
            }
            .v-input--checkbox .theme--light.v-label,
            .v-btn {
                font-size: 14px;
            }
            .v-btn {
                height: 40px !important;
                padding: 0 12px !important;
                margin-right: -12px !important;
            }
        }
    }
    img {
        max-width: 100%;
    }
    ::v-deep {
        .v-input--selection-controls__input {
            margin-right: 4px;
        }
    }
}
@media (max-width: 768px) {
    .popup-layer {
        top: 80px !important;
        left: 0 !important;
        width: calc(100vw - 24px) !important;
        height: auto !important;
        margin: 0 12px;
        &__body {
            width: 100% !important;
            height: auto !important;
        }
    }
}
@keyframes popup-fade {
    0% {
        opacity: 0;
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
